import React, { useContext, useEffect, useCallback } from "react"
import * as Sentry from "@sentry/react"

import {
  getMyTenancy as apiGetMyTenancy,
  getBankDetails as apiGetBankDetails,
  updateBankDetails as apiUpdateBankDetails,
  getBillingPortal as apiGetBillingPortal,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import Form from "../../components/Form"
import AppTitle from "../../components/appTitle/AppTitle"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"
import DialogModal from "../../components/dialogModal"
import Button from "../../components/button/Button"
import { isOnlyLettersAndSpaces } from "../../util/validateStrings"

import * as editProfileStyles from "./editProfile.module.css"

export default ({ location, tenancyId }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)
  const [gettingBankDetails, setGettingBankDetails] = React.useState(true)
  const [updatingBankDetails, setUpdatingBankDetails] = React.useState(false)
  const [updateBankDetailsError, setUpdateBankDetailsError] = React.useState("")
  const [bankDetails, setBankDetails] = React.useState("")
  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)
  const [successMessage, setSuccessMessage] = React.useState("")
  const [showInvalidStripeId, setShowInvalidStripeId] = React.useState(false)

  const getBankDetails = async () => {
    try {
      setGettingBankDetails(true)
      const data = await apiGetBankDetails(tenancyId)
      if (
        data.success &&
        data.value &&
        data.value.bankAccountFullName &&
        data.value.bankAccountNumber
      ) {
        setBankDetails(
          `Account holder: ${data.value.bankAccountFullName}, last 4 digits of the account number: ${data.value.bankAccountNumber}`
        )
      }
    } catch (e) {
      console.log(e)
    } finally {
      setGettingBankDetails(false)
    }
  }

  const updateBankDetails = async payload => {
    setUpdateBankDetailsError("")

    if (
      !payload ||
      !payload.bankAccountFullName ||
      !payload.bankAccountNumber ||
      !payload.bankAccountSortCode
    ) {
      return setUpdateBankDetailsError("Please complete all fields")
    }
    if (!isOnlyLettersAndSpaces(payload.bankAccountFullName)) {
      return setUpdateBankDetailsError("Please enter valid account holder name. Numbers and special characters are not allowed.")
    }
    if (payload.bankAccountNumber.length !== 8 ||
      payload.bankAccountSortCode.join("-").length !== 8) {
      return setUpdateBankDetailsError("Please enter valid bank account details.")
    }

    setUpdateBankDetailsError("")
    setSuccessMessage("")

    try {
      setUpdatingBankDetails(true)
      const data = await apiUpdateBankDetails(tenancyId, {
        ...payload,
        bankAccountSortCode: payload.bankAccountSortCode.join("-"),
      })

      if (!data.success) {
        if (data.errors) {
          setUpdateBankDetailsError(data.errors[0].reason)
        } else {
          setUpdateBankDetailsError(
            "An error occurred trying to update bank details"
          )
        }
      } else {
        setSuccessMessage("Thanks, we've updated your bank details!")
      }
      setUpdatingBankDetails(false)
      getBankDetails()
    } catch (e) {
      setUpdatingBankDetails(false)
      setUpdateBankDetailsError(e.message)
    }
  }

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value) {
        storeTenancy(data.value)
      }
    } catch (e) {
      console.log(e)
      Sentry.captureException(e)
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [tenancyId])

  const getBillingPortal = async () => {
    try {
      var windowReference = window.open()
      const getBillingPortalData = await apiGetBillingPortal(
        tenancy && tenancy.zohoEntityId
      )
      if (getBillingPortalData.value.url) {
        if (typeof window.location !== "undefined") {
          windowReference.location = getBillingPortalData.value.url
        }
      } else {
        setShowInvalidStripeId(true)
        windowReference.close()
      }
    } catch (e) {
      console.log(e)
      windowReference.close()
      setShowInvalidStripeId(true)
    }
  }

  useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  useEffect(() => {
    getBankDetails()
  }, [])

  let editBankDetailsOptions = {
    fields: [
      {
        name: "bankAccountFullName",
        placeholder: "Account holder name",
        type: "text",
        required: true,
        disabled: false,
      },
      {
        name: "bankAccountNumber",
        placeholder: "Account number",
        type: "integerNumberInput",
        required: true,
        disabled: false,
        maxLength: 8
      },
      {
        name: "bankAccountSortCode",
        placeholder: "Sort code",
        type: "doubleNumberInputs",
        separator: "-",
        number: 3,
        required: true,
        disabled: false,
      },
    ],
    submitText: "Save",
  }

  return (
    <PageWrapper
      location={location}
      showLoadingIndicator={gettingBankDetails}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      tenancy={tenancy}
    >
      <div className={editProfileStyles.editProfile}>
        <AppTitle title="Payment Details" />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {tenancy && (
          <>
            <Frame>
              <div style={{ marginBottom: 30, maxWidth: 800 }}>
                We use Stripe to manage your monthly payments, so you can use
                the link below to update your payment details.
              </div>
              <Button
                title="Login to Stripe"
                onClick={() => getBillingPortal()}
              />
            </Frame>
            <Frame>
              <div>
                <div style={{ marginBottom: 30, maxWidth: 800 }}>
                  We need your bank details in order to pay credits and refunds
                  back to your account. We store your information securely, and
                  will delete it when it is no longer required for this purpose
                  only.
                </div>
                <div>
                  {bankDetails && (
                    <div style={{ marginBottom: 20 }}>
                      <div>
                        <b>Current details</b>
                      </div>
                      <div>{bankDetails}</div>
                    </div>
                  )}
                  <div>
                    <Form
                      options={editBankDetailsOptions}
                      onSubmit={payload => updateBankDetails(payload)}
                      submitting={updatingBankDetails}
                      apiErrorMessage={updateBankDetailsError}
                      buttonPosition="floatLeft"
                      initialPayload={{
                        bankAccountSortCode: [],
                        bankAccountFullName: "",
                        bankAccountNumber: "",
                      }}
                      reducedPadding={true}
                      apiSuccessMessage={successMessage}
                    />
                  </div>
                </div>
              </div>
            </Frame>

            <DialogModal
              open={showInvalidStripeId}
              onClose={() => setShowInvalidStripeId(false)}
              title="Invalid Stripe Id"
              cancelText="OK"
            >
              <div style={{ padding: "0 24px 24px 24px" }}>
                You have an invalid Stripe ID. Get in contact with us on{" "}
                <a href="tel:0333 358 3377">0333 358 3377</a>.
              </div>
            </DialogModal>
          </>
        )}
      </div>
    </PageWrapper>
  )
}
