import React, { useRef, useContext, useState, useEffect } from "react"
import { makeStyles } from "@material-ui/core/styles"
import Dialog from "@material-ui/core/Dialog"
import clsx from "clsx"
import CheckIcon from "@material-ui/icons/Check"
import CloseIcon from "@material-ui/icons/Close"


import { Button, MainContent, Slider, FormLabels, RadioButton, ProductTooltip } from "."
import DialogModal from "../../../components/dialogModal"
import FAQSection from "../../../components/faqSection/FAQSection"

import { toPoundFormat, checkIfServiceIsInPackage } from "../services/utils"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../../context/GlobalContextProvider"

const faqContent = {
  hideTitle: true,
  title: "Fixed energy",
  questionsAndAnswers: [
    {
      question: "Has my price been frozen by the government?",
      answer: [
        <div>
          <div style={{ marginBottom: 10 }}>
            With the latest announcement from the government the energy price
            cap has been frozen for two years. As a supplier we have been
            working hard with the necessary parties to ensure that you are
            placed on this tariff from the 1st of October. We have now received
            confirmation that this will be the case.
          </div>
          <div>
            With Bunch, it is still possible that your overall price will be
            increased, however this will only ever be if you have used a greater
            amount of energy than your allowance (you can see this in the
            quoting tool). It also works the other way, and if you don’t spend
            all of your allowance then you will receive money back!
          </div>
        </div>,
      ],
    },
  ],
}

const useStyles = makeStyles(theme => ({
  packageDetails: {
    width: "100%",
    maxWidth: 1400,
    margin: "0 auto",
    color: "#FAFAFA",
    padding: 20,
  },
  services: {
    display: 'flex',
    gap: '20px',
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%"
  },
  service: {
    border: "2px solid #08033D",
    borderRadius: 10,
    padding: 10,
    minHeight: 81,
    marginBottom: 15,
    position: "relative",
    cursor: "pointer",
    color: '#000',
    "-webkit-transition": "border 500ms ease-out",
    "-moz-transition": "border 500ms ease-out",
    "-o-transition": "border 500ms ease-out",
    transition: "border 500ms ease-out",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 30px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 30px 15px",
      width: "100%"
    },
  },
  disabledService: {
    border: "2px solid #08033D",
    borderRadius: 10,
    padding: 10,
    minHeight: 81,
    marginBottom: 15,
    position: "relative",
    cursor: "not-allowed",
    color: '#000',
    "-webkit-transition": "border 500ms ease-out",
    "-moz-transition": "border 500ms ease-out",
    "-o-transition": "border 500ms ease-out",
    transition: "border 500ms ease-out",
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 30px 15px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "15px 15px 30px 15px",
      width: "100%"
    },
    backgroundColor: '#bcbcbc',
  },
  serviceCategroyTitle: {
    fontFamily: "tenon, sans-serif",
    color: '#08033D',
    fontSize: '18pt',
    width: '100%',
    padding: 10,
    textAlign: "center",
    fontWeight: 'Bold'
  },
  selectedService: {
    fontFamily: "tenon, sans-serif",
    backgroundColor: "#08033D",
    color: '#FAFAFA',
  },
  serviceHighlight: {
    fontFamily: "tenon, sans-serif",
    width: "100%",
    fontWeight: "bold",
    fontSize: 20,
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  serviceTitle: {
    fontFamily: "tenon, sans-serif",
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
  },
  serviceSubName: {
    fontSize: 15,
    fontWeight: "normal",
  },
  serviceDetails: {
    position: "relative",
    marginBottom: 20,
    [theme.breakpoints.down("sm")]: {
      paddingLeft: 10,
    },
  },
  serviceInformationWrapper: {
    display: "flex",
    alignItems: "flex-start",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column-reverse",
    },
  },
  serviceInformation: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
  },
  optionSelect: {
    width: "100%",
    // maxWidth: 400,
  },
  submitSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    textAlign: 'right',
    width: "100%",
    padding: "10px 0px",
    background: "#82D0FF"
  },
  totalAmount: {
    fontSize: 30,
    fontWeight: "bold",
    background: "#82D0FF"
  },
  packageSelectionTitle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: 0,
  },
  pricingToggle: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 24,
  },
  toggleServiceSelection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  priceAndSelectButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: 30,
    [theme.breakpoints.down("xs")]: {
      width: "100%",
      marginTop: 20,
    },
    zIndex: 1000,
  },
  priceButton: {
    width: 133,
    textAlign: "right",
  },
  priceModeSelect: {
    color: "grey",
    margin: "0px 5px",
    cursor: "pointer",
    textDecorationLine: 'underline'
  },
  activePriceModeSelect: {
    color: "#081938",
    fontWeight: "bold",
    cursor: "default",
    textDecorationLine: 'none'
  },
  removeButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  tooltip: {
    fontSize: 17,
    opacity: 1,
    zIndex: 9999999999,
  },
  packageServiceImage: {
    width: 100,
    [theme.breakpoints.down("xs")]: {
      wdith: 75,
      marginBottom: 20,
    },
  },
  packageServiceImageSmall: {
    height: 45,
    width: 60,
    marginRight: 15,
    background: '#FAFAFA',
    padding: 3,
    borderRadius: 10,
    textAlign: 'center',
    "& img": {
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  fixedTotalCostWrapper: {
    display: 'none',
    position: "fixed",
    left: 0,
    bottom: 71,
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    background: "#82D0FF",
    zIndex: 10000,
    [theme.breakpoints.down("sm")]: {
      display: "flex",
    },
  },
  fixedTotalCost: {
    width: "100%",
    maxWidth: "700px",
    padding: "0px 20px",
  },
  checkIconWrapper: {
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: -25,
    left: 0,
  },
  checkIcon: {
    height: 50,
    width: 50,
    background: "#AEAEFF",
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  addToPackageWrapper: {
    height: 50,
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: -25,
    left: 0,
  },
  addToPackage: {
    height: 50,
    backgroundColor: "#FAFAFA",
    padding: "0px 20px",
    borderRadius: 25,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#08033D",
    cursor: "pointer",
    border: "2px solid #08033D"
  },
  viewMoreService: {
    "& .MuiDialog-scrollPaper": {
      alignItems: "flex-start",
      marginTop: "50px",
      [theme.breakpoints.down("sm")]: {
        marginTop: "0px",
      },
    },
    "& .MuiPaper-root": {
      backgroundColor: "transparent",
      color: "#FAFAFA",
      boxShadow: "none",
      width: "100%",
      height: "calc(100% - 250px)",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100% - 140px)",
      },
    },
  },
  viewMoreServiceTitle: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    fontWeight: "bold",
    fontSize: 25,
    marginBottom: "10px",
  },
  viewMoreServiceSubName: {
    fontSize: 17,
    fontWeight: "normal",
  },
  closeIconWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "fixed",
    left: 0,
    width: "100%",
    textAlign: "center",
    bottom: 60,
    [theme.breakpoints.down("md")]: {
      bottom: 20,
    },
  },
  closeIconContent: {
    width: 70,
    height: 70,
    borderRadius: 35,
    cursor: "pointer",
    border: "2px solid white",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  closeIcon: {
    fontSize: 30,
  },
  serviceFeature: {
    margin: "5px 0px",
    fontSize: '15px',
    display: 'flex',
    alignItems: 'flex-start',
    gap: "7px"
  },
  featureIcon: {
    width: 20,
    height: 20,
    borderRadius: 10,
    color: "#08033D",
    border: "1px solid #08033D",
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "center",
  },
  activeFeatureIcon: {
    color: '#FAFAFA',
    border: "1px solid #fff",
  }
}))


export default function PackageSelection({
  label,
  description,
  popupText,
  tenancyDetails,
  setTenancyDetails,
  packageDetails,
  setPackageDetails,
  services,
  availableBroadbandProducts,
  products,
  matchingQuotingToolVersion,
  energyAllowanceControl,
  energyAllowanceProductIds,
  showServicePrices,
  showWeeklyPrice,
  showMonthlyPrice,
  showYearlyPrice,
  backgroundColor,
  hideContinue,
  isHomeownerOrRenter,
  isScottishPostcode
}) {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const classes = useStyles()

  const serviceRef = useRef({})
  const totalCostRef = useRef()

  const [openSection, setOpenSection] = useState("Fixed energy")
  const [openQuestion, setOpenQuestion] = useState(0)

  const [showFixedTotalCost, setShowFixedTotalCost] = useState(true)
  const [showProductWarning, setShowProductWarning] = useState(null)
  const [
    shownProductWarningPreviously,
    setShownProductWarningPreviously,
  ] = useState([])
  const [showCheaperPriceInfo, setShowCheaperPriceInfo] = useState(false)
  const [viewMoreService, setViewMoreService] = useState(null)
  const validVersionsForSlider = ['residential', 'tool-a', 'internal-student', 'internal-resi'];
  const showEnergySlider = validVersionsForSlider.includes(matchingQuotingToolVersion.versionId)

  const listenToScroll = () => {
    const heightToHide = totalCostRef?.current?.getBoundingClientRect().top
    const windowScrollHeight = window.innerHeight
    if (windowScrollHeight > heightToHide + 120) {
      setShowFixedTotalCost(false)
    } else {
      setShowFixedTotalCost(true)
    }
  }

  function checkBroadbandServiceOption() {
    const broadbandPackageIndex = packageDetails.findIndex(pkg => pkg.category === "Broadband");

    if (broadbandPackageIndex !== -1) {
      const broadbandPackage = packageDetails[broadbandPackageIndex];

      let broadbandServiceOptionId = broadbandPackage.serviceOptionId
      let isBroadbandOptionInProducts = (products || "").indexOf(broadbandServiceOptionId) !== -1

      if (!isBroadbandOptionInProducts) {
        const broadbandService =
          services &&
          services
            .filter(
              service =>
                service.id == broadbandPackage.serviceId
            )

        if (broadbandService) {
          const serviceOptions = broadbandService[0].options
            .filter(option => (products || "").indexOf(option.id) !== -1)
            .sort((x, y) => x.displayOrder - y.displayOrder)

          if (serviceOptions.length > 0) {
            const updatedBroadbandPackage = {
              ...broadbandPackage,
              serviceOptionId: serviceOptions[0].id,
            };

            // Create a new packageDetails array with the updated broadband package
            const updatedPackageDetails = [...packageDetails];
            updatedPackageDetails[broadbandPackageIndex] = updatedBroadbandPackage;

            // Assuming setPackageDetails is a function to update packageDetails
            setPackageDetails(updatedPackageDetails);
          }
        }
      }
    }
  }

  const quotingToolMultipliers = matchingQuotingToolVersion.quotingToolPages
    .map(quotingToolPage =>
      quotingToolPage.quotingToolPageFields
        .filter(
          quotingToolField => quotingToolField.quotingToolMultipliers.length > 0
        )
        .map(quotingToolField => quotingToolField.quotingToolMultipliers)
    )
    .flat()

  React.useEffect(() => {
    listenToScroll()
    checkBroadbandServiceOption()
  }, [])

  React.useEffect(() => {
    window.addEventListener("scroll", listenToScroll)
    return () => window.removeEventListener("scroll", listenToScroll)
  }, [])

  const getDefaultPriceMode = () => {
    if (showWeeklyPrice) {
      return "week"
    } else if (showMonthlyPrice) {
      return "month"
    }
    return "year"
  }

  useEffect(() => {
    if (isHomeownerOrRenter) {
      dispatch({ type: "setPriceModeView", priceModeView: "month" })
    }
  }, [isHomeownerOrRenter])

  // This code calculates prices if there are any multipliers that come from the answers to previous questions
  // TODO: We need to simplify this code at some point in the future
  const getServiceCost = (services, packageDetails, service, allowance, numberOfSkyRooms) => {
    let serviceCost = 0

    if (service.freeWithUnlimited) {
      let unlimitedServiceIds = services.filter(_ => _.unlimitedPackage).map(_ => _.id);

      if (packageDetails.filter(_ => unlimitedServiceIds.find(x => x === _.serviceId)).length > 0) {
        return 0;
      }
    }

    if (service.products && service.products.length > 0) {
      for (let i = 0; i < service.products.length; i++) {
        let serviceProduct = service.products[i]
        let serviceProductCost = 0

        // Calculate the cost of the allowance (ignoring the standing charge)
        var allowanceTotal = serviceProduct.allowance * serviceProduct.sellRate1

        if (serviceProduct.sellRate2 > 0) {
          var averageNightUsage = 0.42;
          allowanceTotal = allowanceTotal = (serviceProduct.allowance * (1 - averageNightUsage) * serviceProduct.sellRate1) + (serviceProduct.allowance * averageNightUsage * serviceProduct.sellRate2);
        }

        if (serviceProduct.name === "Canopy Grow") {
          serviceProduct['costPerWeek'] *= tenancyDetails["NumberOfBillPayers"]
        }

        if (context.priceModeView === "week") {
          serviceProductCost = serviceProduct.costPerWeek
          allowanceTotal = allowanceTotal / 52
        } else if (context.priceModeView === "month") {
          serviceProductCost = serviceProduct.costPerMonth
          allowanceTotal = allowanceTotal / 12
        } else if (context.priceModeView === "year") {
          serviceProductCost = serviceProduct.costPerYear
        }

        if (serviceProduct.name === 'Canopy Grow') {
          serviceCost += serviceProductCost * tenancyDetails["NumberOfBillPayers"]
        }

        var allowanceCost = 0

        if (allowance && serviceProduct.name !== "Canopy Grow") {
          var numberOfBedrooms = isHomeownerOrRenter && tenancyDetails["NumberOfBillPayers"]
            ? tenancyDetails["NumberOfBillPayers"]
            : tenancyDetails["NumberOfTenants"]

          allowanceCost = Math.round((allowanceTotal / numberOfBedrooms) * allowance, 2)
        }

        serviceProductCost += allowanceCost

        serviceCost += serviceProductCost

        if (numberOfSkyRooms) {
          serviceCost = serviceCost * numberOfSkyRooms
        }
      }

    } else {
      if (context.priceModeView === "week") {
        serviceCost = service.costPerWeek
      } else if (context.priceModeView === "month") {
        serviceCost = service.costPerMonth
      } else if (context.priceModeView === "year") {
        serviceCost = service.costPerYear
      }

      var allowanceCost = 0

      if (allowance) {
        allowanceCost += serviceCost * (allowance / 100)
      }

      serviceCost += allowanceCost

      if (numberOfSkyRooms) {
        serviceCost = serviceCost * numberOfSkyRooms
      }
    }

    return serviceCost
  }

  const getPriceLabel = () => {
    if (context.priceModeView === "week") {
      return "/week"
    } else if (context.priceModeView === "month") {
      return "/month"
    } else if (context.priceModeView === "year") {
      return "/year"
    }
  }

  var servicesToDisplayPre =
    services &&
    services
      .filter(
        service =>
          (products || "").indexOf(service.id) !== -1 &&
          (service.displayInQuote === "0. Mandatory Package Section" ||
            service.displayInQuote === "1. Package Section" ||
            service.displayInQuote === "3. Summary Section")
      )
      .sort((x, y) => x.displayOrder - y.displayOrder)

  const servicesToDisplay = makeCanopyUnavailableIfPreselect(servicesToDisplayPre)

  var matchingServiceWidth = (service) => {
    var matching = servicesToDisplay.filter(_ => _.category === "Energy").length;
    return `calc(${100 / highestValue}% - 20px)`;
  }

  const findSelectedServices = (serviceId) => {
    var service = servicesToDisplay.filter(_ => _.id === serviceId)[0]
    return service;
  }

  const findCanopyGrow = servicesToDisplay.filter(_ => _.name === "Canopy Grow")

  const addPackageService = packageService => {
    let newPackageDetails = packageDetails

    // Need to check if a service with the same type has already been added, and we need to remove that one
    const existingServiceTypeIndex = packageDetails.findIndex(

      existingPackageService =>
        existingPackageService.category === packageService.category

    )

    if (existingServiceTypeIndex !== -1) {
      newPackageDetails = [
        ...newPackageDetails.slice(0, existingServiceTypeIndex),
        ...newPackageDetails.slice(
          existingServiceTypeIndex + 1,
          newPackageDetails.length
        ),
      ]
    }

    const existingServiceIndex = newPackageDetails.findIndex(
      existingPackageService =>
        existingPackageService.serviceId === packageService.serviceId
    )

    const service = findSelectedServices(packageService.serviceId)

    if (findCanopyGrow.length > 0) {
      const existingCanopyServiceIndex = newPackageDetails.findIndex(
        existingPackageService =>
          existingPackageService.serviceId === findCanopyGrow[0].id
      )

      // We don't want to add canopy if we have a product that contains canopy
      const existingServiceIndexIncludesCanopy = newPackageDetails.findIndex(
        existingPackageService =>
          service.includesCanopySubscription
      )

      if (findSelectedServices(packageService.serviceId).includesCanopySubscription) {
        if (findCanopyGrow.length === 1) {
          newPackageDetails.splice(existingCanopyServiceIndex, 1);
        }
        findCanopyGrow[0].unavailableMessage = "Canopy Grow is included with your Unlimited Energy package.";
        findCanopyGrow[0].unavailable = true;
      }

      if (existingCanopyServiceIndex !== -1 && findSelectedServices(packageService.serviceId).includesCanopySubscription) {
        packageDetails.splice(existingCanopyServiceIndex, 1);
        findCanopyGrow[0].unavailableMessage = "Canopy Grow is included with your Unlimited Energy package.";
        findCanopyGrow[0].unavailable = true;
      }

      if ((findSelectedServices(packageService.serviceId).includesCanopySubscription === false && service.category === "Energy")) {
        findCanopyGrow[0].unavailableMessage = "Canopy Grow is included with your Unlimited Energy package.";
        findCanopyGrow[0].unavailable = false;
      }

      if (existingServiceIndexIncludesCanopy !== -1) {
        if (findCanopyGrow.length === 1) {
          findCanopyGrow[0].unavailableMessage = "Canopy Grow is included with your Unlimited Energy package";
          findCanopyGrow[0].unavailable = true
        }
      }
    }

    if (existingServiceIndex !== -1) {
      newPackageDetails = [
        ...newPackageDetails.slice(0, existingServiceIndex),
        packageService,
        ...newPackageDetails.slice(
          existingServiceIndex + 1,
          newPackageDetails.length
        ),
      ]
    } else {
      newPackageDetails = [...newPackageDetails, packageService]
    }

    setPackageDetails(newPackageDetails)
  }

  const removePackageService = serviceId => {
    const existingServiceIndex = packageDetails.findIndex(
      existingPackageService => existingPackageService.serviceId === serviceId
    )

    if (findSelectedServices(serviceId).includesCanopySubscription) {
      if (findCanopyGrow.length === 1) {
        findCanopyGrow[0].unavailable = false
      }
    }

    if (serviceId !== -1) {
      setPackageDetails([
        ...packageDetails.slice(0, existingServiceIndex),
        ...packageDetails.slice(
          existingServiceIndex + 1,
          packageDetails.length
        ),
      ])
    }
  }

  function removeWaterCheck(servicesToDisplay) {
    if (isScottishPostcode) {
      let waterServiceIndex = servicesToDisplay.findIndex(_ => _.category === "Water")

      if (waterServiceIndex !== -1) {
        servicesToDisplay.splice(waterServiceIndex, 1)
      }

      return servicesToDisplay
    }
    return servicesToDisplay
  }

  function groupProductsByCategory(products) {
    const groupedProducts = {
      Energy: [],
      Broadband: [],
      TV: [],
      Other: [],
      OffersAndPromotions: [],
    };

    for (const product of products) {
      switch (product.category) {
        case "Energy":
          groupedProducts.Energy.push(product);
          break;
        case "Broadband":
          groupedProducts.Broadband.push(product);
          break;
        case "Entertainment":
          groupedProducts.TV.push(product);
          break;
        case "Free Gifts":
          groupedProducts.OffersAndPromotions.push(product);
          break;
        case "Promotions":
          groupedProducts.OffersAndPromotions.push(product);
          break;
        default:
          groupedProducts.Other.push(product);
      }
    }

    for (const category in groupedProducts) {
      if (groupedProducts[category].length === 0) {
        delete groupedProducts[category];
      }
    }

    return groupedProducts;
  }

  const groupedProducts = groupProductsByCategory(servicesToDisplay);

  function makeCanopyUnavailableIfPreselect(products) {
    for (const product of products) {
      if (product.includesCanopySubscription && product.options[0].selectedByDefault) {
        const index = products.map(e => e.name).indexOf("Canopy Grow");
        if (index !== -1) {
          products[index].unavailableMessage = "Canopy Grow is included with your Unlimited Energy package.";
          products[index].unavailable = true;
        }
        product.options[0].selectedByDefault = false
      }
    }

    return products
  }

  function findMaxElementsPerCategory(groupedProducts) {
    const maxElements = {};
    let highestValue = -1; // Initialize with a negative value

    for (const category in groupedProducts) {
      const categoryLength = groupedProducts[category].length;
      maxElements[category] = categoryLength;

      if (categoryLength > highestValue) {
        highestValue = categoryLength;
      }
    }

    return highestValue > 3 ? 3 : highestValue;
  }

  const highestValue = findMaxElementsPerCategory(groupedProducts);

  const totalCost =
    packageDetails &&
    packageDetails
      .map(packageService => {
        const matchingService = services.find(
          service => service.id === packageService.serviceId
        )
        if (matchingService !== -1 && matchingService != null) {
          let serviceCost = 0
          if (packageService.serviceOptionId) {
            let option = matchingService.options.find(
              option => option.id === packageService.serviceOptionId
            )
            if (option) {
              serviceCost = getServiceCost(services, packageDetails, option, packageService.allowance, packageService.numberOfSkyRooms)
            }
          } else {
            serviceCost = getServiceCost(
              services, packageDetails,
              matchingService,
              packageService.allowance,
              packageService.numberOfSkyRooms
            )
          }

          return serviceCost
        }
        return 0
      })
      .reduce((previousValue, currentValue) => previousValue + currentValue, 0)

  const renderProductFeatures = (viewMoreService) => {
    const packageService = checkIfServiceIsInPackage(packageDetails, viewMoreService.id)

    const packageServiceOption =
      packageService &&
      packageService.serviceOptionId &&
      viewMoreService.options.find(
        option =>
          packageService &&
          packageService.serviceOptionId &&
          option.id === packageService.serviceOptionId
      )

    let features =
      packageServiceOption && packageServiceOption.productFeatures
        ? packageServiceOption.productFeatures
        : viewMoreService.productFeatures
    return (
      features &&
      features.split("\n").map(feature => (
        <div
          className={clsx(classes.serviceFeature, "quotingTool-serviceFeature")}
        >
          <div className={clsx(classes.featureIcon, {
            [classes.activeFeatureIcon]: packageService
          })}>
            <CheckIcon style={{ fontSize: 15 }} />
          </div>{" "}
          <div style={{ marginTop: -3, width: "calc(100% - 30px)" }}>{feature}</div>
        </div>
      ))
    )
  }

  const numberOfBillPayers = parseInt(tenancyDetails.NumberOfTenants ?? tenancyDetails.NumberOfBillPayers);

  return (
    <div className={classes.packageDetails}>
      {!hideContinue && showFixedTotalCost && (
        <div
          className={clsx(
            classes.fixedTotalCostWrapper,
            "quotingTool-fixedTotalCostWrapper"
          )}
        >
          <div className={classes.fixedTotalCost}>
            <div
              className={clsx(
                classes.submitSection,
                "quotingTool-submitSection"
              )}
            >
              <div>
                <div
                  className={clsx(classes.totalAmount, "quotingTool-totalAmount")}
                >
                  {toPoundFormat(totalCost)}
                  {getPriceLabel()}
                </div>
                {numberOfBillPayers > 1 && <div style={{ fontSize: 12 }}>per bill-payer</div>}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={classes.packageSelectionTitle}>
        <div></div>
        <div>
          <div className={classes.pricingToggle}>
            {showWeeklyPrice && (
              <>
                <div
                  className={clsx(
                    classes.priceModeSelect,
                    {
                      [classes.activePriceModeSelect]: context.priceModeView === "week",
                    },
                    "quotingTool-priceModeSelect"
                  )}
                  onClick={() => dispatch({ type: "setPriceModeView", priceModeView: "week" })}
                >
                  Weekly
                </div>{" "}
              </>
            )}
            {showMonthlyPrice && (
              <>
                {showWeeklyPrice && <span style={{ color: '#08033D' }}>/ </span>}
                <div
                  className={clsx(
                    classes.priceModeSelect,
                    {
                      [classes.activePriceModeSelect]: context.priceModeView === "month",
                    },
                    "quotingTool-priceModeSelect"
                  )}
                  onClick={() => dispatch({ type: "setPriceModeView", priceModeView: "month" })}
                >
                  Monthly
                </div>{" "}
              </>
            )}
            {showYearlyPrice && (
              <>
                {(showWeeklyPrice || showMonthlyPrice) && <span style={{ color: '#82D0FF' }}>/ </span>}
                <div
                  className={clsx(
                    classes.priceModeSelect,
                    {
                      [classes.activePriceModeSelect]: context.priceModeView === "year",
                    },
                    "quotingTool-priceModeSelect"
                  )}
                  onClick={() => dispatch({ type: "setPriceModeView", priceModeView: "year" })}
                >
                  Yearly
                </div>
              </>
            )}
          </div>

          {/* per bill-payer / household */}
          <div className={classes.pricingToggle}>
            {numberOfBillPayers > 1 && (
              <div className={classes.pricingToggle}>
                <div
                  className={clsx(classes.priceModeSelect, {
                    [classes.activePriceModeSelect]: context.individualPriceModeView === "bill-payer",
                    ["quotingTool-selectedIndividualPriceView"]: context.individualPriceModeView === "bill-payer",
                    ["quotingTool-unselectedIndividualPriceView"]: context.individualPriceModeView !== "bill-payer",
                  })}
                  onClick={() => dispatch({ type: "setIndividualPriceModeView", individualPriceModeView: "bill-payer" })}
                  onKeyPress={() => dispatch({ type: "setIndividualPriceModeView", individualPriceModeView: "bill-payer" })}
                  role="button"
                  tabIndex="0"
                >
                  Bill-payer
                </div>
                <span style={{ color: '#58be7a' }}>/ </span>
                <div
                  className={clsx(classes.priceModeSelect, {
                    [classes.activePriceModeSelect]: context.individualPriceModeView === "household",
                    ["quotingTool-selectedIndividualPriceView"]: context.individualPriceModeView === "household",
                    ["quotingTool-unselectedIndividualPriceView"]: context.individualPriceModeView !== "household",
                  })}
                  onClick={() => dispatch({ type: "setIndividualPriceModeView", individualPriceModeView: "household" })}
                  onKeyPress={() => dispatch({ type: "setIndividualPriceModeView", individualPriceModeView: "household" })}
                  role="button"
                  tabIndex="0"
                >
                  Household
                </div>
              </div>
            )}
          </div>
        </div>

      </div>



      <div className={classes.services}>
        {Object.keys(groupedProducts).map(category => {
          const productsInCategory = groupedProducts[category];

          let title = "";

          switch (category) {
            case "Other":
              title = "Other Services";
              break;
            case "TV":
              title = "Sky TV";
              break;
            case "OffersAndPromotions":
              title = "Offers & Promotions";
              break;
            default:
              title = category
              break;   
          }

          return (
            <div className={classes.services}>
              <div className={classes.serviceCategroyTitle}>{title}</div>

              {groupedProducts[category] &&
                removeWaterCheck(groupedProducts[category]).map(serviceToDisplay => {
                  const serviceOptions = serviceToDisplay.options
                    .filter(option => (products || "").indexOf(option.id) !== -1)
                    .filter(option => {
                      if (availableBroadbandProducts) {
                        let matchingBroadbandProducts = availableBroadbandProducts.filter(
                          broadbandProduct =>
                            broadbandProduct.parentProductId === serviceToDisplay.id
                        )

                        // Return false if the available broadband products isn't empty, and doesn't contain the current option
                        if (
                          matchingBroadbandProducts.length > 0 &&
                          !matchingBroadbandProducts.find(
                            broadbandProduct => broadbandProduct.id === option.id
                          )
                        ) {
                          return false
                        }
                      }
                      return true
                    })
                    .sort((x, y) => x.displayOrder - y.displayOrder)

                  // This conifgures the slider that gets shown when setting the allowance for a product
                  let allowanceMarks = []
                  if (
                    energyAllowanceControl &&
                    energyAllowanceControl.productAllowanceOptions &&
                    energyAllowanceControl.productAllowanceOptions.length > 1
                  ) {
                    allowanceMarks = energyAllowanceControl.productAllowanceOptions.map(
                      (option, index) => ({
                        value: index + 1,
                        label: option.label,
                      })
                    )
                  }

                  // This configures the slider that gets shown when there are multiple options for a product
                  let showOptionsSlider = false
                  let sliderMarks = []
                  if (serviceOptions.length > 0) {
                    showOptionsSlider = true
                    sliderMarks = serviceOptions.map((option, index) => ({
                      value: index + 1,
                      label: option.name,
                      // label: option.name.split(" ")[0].trim(),
                    }))
                  }

                  const packageService = checkIfServiceIsInPackage(packageDetails,
                    serviceToDisplay.id
                  )

                  const packageServiceOption =
                    packageService &&
                    packageService.serviceOptionId &&
                    serviceOptions.find(
                      option =>
                        packageService &&
                        packageService.serviceOptionId &&
                        option.id === packageService.serviceOptionId
                    )

                  let serviceCost = 0

                  if (packageService && packageService.serviceOptionId) {
                    let option = serviceOptions.find(
                      option => option.id === packageService.serviceOptionId
                    )
                    if (option) {
                      serviceCost = getServiceCost(services, packageDetails, option, packageService.allowance, packageService.numberOfSkyRooms)
                    }
                  } else {
                    serviceCost = getServiceCost(
                      services, packageDetails,
                      serviceToDisplay,
                      packageService && packageService.allowance,
                      packageService && packageService.numberOfSkyRooms
                    )
                  }

                  return (

                    <div
                      className={
                        serviceToDisplay.disabled ?
                          clsx(classes.disabledService, "disabledService") :
                          clsx(classes.service, "quotingTool-service", {
                            [classes.selectedService]: packageService,
                            ["quotingTool-selectedService"]: packageService,
                          }
                          )}
                      style={{
                        width: typeof window !== "undefined" && window.innerWidth < 944 ? "100%"
                          : matchingServiceWidth(serviceToDisplay.category),
                        cursor:
                          serviceToDisplay.displayInQuote === "3. Summary Section" &&
                          "default",
                      }}
                      key={serviceToDisplay.id}
                      ref={ref => {
                        serviceRef.current[serviceToDisplay.id] = ref
                      }}
                      onClick={e => {
                        e.stopPropagation()
                        if (serviceToDisplay.disabled)
                          return;
                        if (
                          serviceToDisplay.displayInQuote !== "3. Summary Section" &&
                          !serviceToDisplay.unavailable
                        ) {
                          if (!packageService) {
                            let serviceDetails = {
                              serviceId: serviceToDisplay.id,
                              category: serviceToDisplay.category,
                            }

                            if (
                              energyAllowanceProductIds.indexOf(
                                serviceToDisplay.id
                              ) !== -1
                            ) {
                              serviceDetails.allowance = 0
                            }

                            if (serviceOptions.length > 0) {
                              serviceDetails.serviceOptionId = serviceOptions.sort(
                                (x, y) => x.displayOrder - y.displayOrder
                              )[0].id
                            }

                            if (serviceOptions.length > 0) {
                              let serviceOption = serviceOptions.sort(
                                (x, y) => x.displayOrder - y.displayOrder
                              )[0]

                              serviceDetails.serviceOptionId = serviceOption.id

                              if (serviceOptions.length > 1) {
                                if (
                                  serviceOption.productWarning &&
                                  shownProductWarningPreviously.indexOf(
                                    serviceOption.id
                                  ) === -1
                                ) {
                                  setShowProductWarning(serviceOption.productWarning)

                                  setShownProductWarningPreviously([
                                    ...shownProductWarningPreviously,
                                    serviceOption.id,
                                  ])
                                }
                              }
                            }
                            addPackageService(serviceDetails)
                          } else {
                            removePackageService(serviceToDisplay.id)
                          }
                        }
                      }}
                    >
                      <div
                        className={clsx(
                          classes.serviceHighlight,
                          "quotingTool-serviceHighlight"
                        )}
                      >
                        <div
                          style={{ width: "100%" }}
                          className={clsx(
                            classes.serviceTitle,
                            "quotingTool-serviceTitleSection"
                          )}
                        >
                          {serviceToDisplay &&
                            (serviceToDisplay.supplierImageUrl ||
                              serviceToDisplay.imageUrl) && (
                              <div
                                className={clsx(
                                  classes.packageServiceImageSmall,
                                  "quotingTool-packageServiceImageSmall"
                                )}>
                                <img
                                  src={
                                    serviceToDisplay.supplierImageUrl ||
                                    serviceToDisplay.imageUrl
                                  }
                                />
                              </div>
                            )}
                          <div>
                            <div style={{
                              marginTop: "-4px",
                              lineHeight: "23px"
                            }} className="quotingTool-serviceTitle">
                              {serviceToDisplay.name} {serviceToDisplay.description && <ProductTooltip description={serviceToDisplay.description} />}
                            </div>
                          </div>
                        </div>
                        <div className={clsx(classes.serviceSubName, "quotingTool-serviceSubName")} style={{ marginTop: 10 }}>
                          {serviceToDisplay.subName}
                          {(serviceToDisplay.includesCanopySubscription) && <div>
                            <img style={{ height: 18, marginTop: 20, marginBottom: 10 }} src={packageService ? "https://images.the-bunch.co.uk/canopy-grow-white.svg" : "https://images.the-bunch.co.uk/canopy-grow.svg"} />
                            <ProductTooltip description={"We'll report your on time monthly payments to major credit score companies so you can supercharge your credit score without lifting a finger!"} />
                          </div>}
                        </div>
                        <div style={{ marginTop: 10 }}>{renderProductFeatures(serviceToDisplay)}</div>
                      </div>
                      {packageService &&
                        (showOptionsSlider ||
                          serviceToDisplay.allowance ||
                          energyAllowanceProductIds.indexOf(serviceToDisplay.id) !==
                          -1) && (
                          <div
                            className={clsx(
                              classes.serviceDetails,
                              "quotingTool-serviceDetails"
                            )}
                          >
                            <div
                              className={clsx(
                                classes.serviceInformationWrapper,
                                "quotingTool-serviceInformationWrapper"
                              )}
                            >
                              <div
                                className={clsx(
                                  classes.serviceInformation,
                                  "quotingTool-serviceInformation"
                                )}
                              >
                                {showEnergySlider && packageService &&
                                  energyAllowanceControl &&
                                  energyAllowanceProductIds.indexOf(
                                    serviceToDisplay.id
                                  ) !== -1 && (
                                    <div
                                      className={classes.optionSelect}
                                      style={{ opacity: !packageService ? 0.3 : 1 }}
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <div style={{ fontSize: 14, fontStyle: 'italic' }}>Move the slider if your usage is likely to be over or under the recommended amount</div>
                                      <Slider
                                        disabled={!packageService}
                                        value={
                                          energyAllowanceControl.productAllowanceOptions.findIndex(
                                            option =>
                                              packageService &&
                                              packageService.allowance !== null &&
                                              option.value ===
                                              packageService.allowance
                                          ) + 1
                                        }
                                        aria-labelledby="discrete-slider-small-steps"
                                        step={1}
                                        marks={allowanceMarks}
                                        min={1}
                                        max={
                                          energyAllowanceControl
                                            .productAllowanceOptions.length
                                        }
                                        valueLabelDisplay="auto"
                                        onChange={val => {
                                          let serviceDetails = packageService || {
                                            serviceId: serviceToDisplay.id,
                                          }

                                          setTenancyDetails({
                                            HasModifiedAllowanceSlider: true,
                                            LastAllowanceSelected:
                                              energyAllowanceControl
                                                .productAllowanceOptions[val - 1]
                                                .value,
                                          })

                                          addPackageService({
                                            ...serviceDetails,
                                            allowance:
                                              energyAllowanceControl
                                                .productAllowanceOptions[val - 1]
                                                .value,
                                          })
                                        }}
                                      />
                                    </div>
                                  )}
                                {packageService &&
                                  showOptionsSlider &&
                                  energyAllowanceProductIds.indexOf(
                                    serviceToDisplay.id
                                  ) === -1 &&
                                  sliderMarks.length > 1 && (
                                    <div
                                      className={classes.optionSelect}
                                      style={{
                                        opacity: !packageService ? 0.3 : 1,
                                        marginBottom: 50,
                                      }}
                                      onClick={e => e.stopPropagation()}
                                    >
                                      <RadioButton
                                        disabled={!packageService}
                                        value={
                                          serviceOptions.findIndex(
                                            option =>
                                              packageService &&
                                              packageService.serviceOptionId &&
                                              option.id ===
                                              packageService.serviceOptionId
                                          ) + 1
                                        }
                                        marks={sliderMarks}
                                        valueLabelDisplay="auto"
                                        onChange={val => {
                                          let serviceDetails = {
                                            serviceId: serviceToDisplay.id,
                                            category: serviceToDisplay.category,
                                            serviceOptionId:
                                              serviceOptions[val - 1].id,
                                          }

                                          if (
                                            serviceOptions[val - 1].productWarning &&
                                            shownProductWarningPreviously.indexOf(
                                              serviceOptions[val - 1].id
                                            ) === -1
                                          ) {
                                            setShowProductWarning(
                                              serviceOptions[val - 1].productWarning
                                            )

                                            setShownProductWarningPreviously([
                                              ...shownProductWarningPreviously,
                                              serviceOptions[val - 1].id,
                                            ])
                                          }

                                          addPackageService(serviceDetails)
                                        }}
                                      />
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>
                        )}

                      {packageService && packageService.category === "Entertainment" &&
                        <div
                          style={{ marginBottom: '30px' }}
                          onClick={e => e.stopPropagation()}>
                          {packageService.name}
                          <img style={{ height: 75, marginTop: 10 }} src={"https://images.the-bunch.co.uk/EntertainmentMixLogo.jpg"} />
                          <img style={{ height: 75, marginTop: 10 }}
                            src={(serviceToDisplay.name === "Cinema & Entertainment Package" || serviceToDisplay.name === "Full Package" ?
                              "https://images.the-bunch.co.uk/CinemaMixLogo.jpg" : "")} />
                          <img style={{ height: 75, marginTop: 10 }}
                            src={(serviceToDisplay.name === "Sports & Entertainment Package" || serviceToDisplay.name === "Full Package" ?
                              "https://images.the-bunch.co.uk/SportsMixLogo.jpg" : "")} />
                          <img style={{ width: "100%", marginTop: 10 }} src={serviceToDisplay.name === "Entertainment Package" ? "https://images.the-bunch.co.uk/EntertainmentMixChannels.jpg" :
                            (serviceToDisplay.name === "Cinema & Entertainment Package" ? "https://images.the-bunch.co.uk/CinemaMixChannels.jpg" :
                              (serviceToDisplay.name === "Sports & Entertainment Package" ? "https://images.the-bunch.co.uk/SportsMixChannels.jpg" : ""))} />
                          <div style={{ marginBottom: '10px' }}>
                            Please select how many rooms in the house you would like to have SkyTV. The price shown below is the total cost.
                          </div>

                          <div style={{ borderRadius: '10px', display: 'inline-block', overflow: 'hidden' }}>
                            <select
                              style={{ padding: '5px 10px', textAlign: 'center' }}
                              onChange={e => {
                                let serviceDetails = packageService || {
                                  serviceId: serviceToDisplay.id,
                                }
                                serviceDetails.numberOfSkyRooms = e.target.value

                                addPackageService({
                                  ...serviceDetails,
                                  numberOfSkyRooms: e.target.value,
                                })
                              }}>
                              {[1, 2, 3, 4, 5].map((number) => (
                                <option key={number} value={number}>
                                  {number}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      }

                      {packageService &&
                        serviceToDisplay.category !== "Energy" &&
                        serviceOptions.length === 1 && (
                          <div style={{ marginTop: 20 }}>
                            {serviceOptions[0].name}
                          </div>
                        )}
                      {serviceToDisplay.displayInQuote !== "3. Summary Section" &&
                        packageService && (
                          <div className={classes.checkIconWrapper}>
                            <div
                              className={clsx(
                                classes.checkIcon,
                                "quotingTool-serviceSelectedCheck"
                              )}
                            >
                              <CheckIcon style={{ color: "#081938", fontSize: 30 }} />
                            </div>
                          </div>
                        )}
                      {serviceToDisplay.displayInQuote !== "3. Summary Section" &&
                        !packageService &&
                        !serviceToDisplay.unavailable && !serviceToDisplay.disabled && (
                          <div className={classes.addToPackageWrapper}>
                            <div
                              className={clsx(
                                classes.addToPackage,
                                "quotingTool-serviceSelectedCheck",
                                "noselect"
                              )}
                            >
                              Add to package
                            </div>
                          </div>
                        )}
                      {serviceToDisplay.message && packageService && (
                        <div style={{ marginTop: 20, fontSize: 15 }}>
                          {serviceToDisplay.message}
                        </div>
                      )}
                      {serviceToDisplay.displayInQuote !== "3. Summary Section" &&
                        serviceToDisplay.unavailable && (
                          <div style={{ marginTop: 20 }}>
                            ⚠️{" "}
                            {serviceToDisplay.unavailableMessage ||
                              "This service is unavailable for your property."}
                          </div>
                        )}
                      <div
                        className={clsx(
                          classes.priceAndSelectButton,
                          "quotingTool-priceAndSelectButtonSection"
                        )}
                      >
                        {showServicePrices && (serviceToDisplay.displayInQuote === "3. Summary Section" || serviceCost > 0) && (
                          <div style={{ textAlign: 'right' }}>
                            <div
                              className={clsx(
                                classes.priceButton,
                                "quotingTool-servicePrice"
                              )}
                            >
                              {toPoundFormat(context.individualPriceModeView === "bill-payer" ? serviceCost : serviceCost * numberOfBillPayers)}
                              {getPriceLabel()}
                            </div>
                            {numberOfBillPayers > 1 && <div style={{ fontSize: 12 }}>{context.individualPriceModeView === "bill-payer" ? "per bill-payer" : "per household"}</div>}
                          </div>
                        )}
                      </div>
                    </div>
                  )
                })}
            </div>
          );
        })}
      </div>


      <Dialog
        open={viewMoreService}
        onClose={() => setViewMoreService(null)}
        quotingTool={true}
        className={classes.viewMoreService}
        BackdropProps={{
          style: {
            backgroundColor: "#22544eeb",
          },
        }}
        maxWidth={
          viewMoreService &&
            energyAllowanceProductIds.indexOf(viewMoreService.id) !== -1
            ? "md"
            : "sm"
        }
      >
        {viewMoreService && (
          <div>
            <div
              className={clsx(
                classes.viewMoreServiceTitle,
                "quotingTool-serviceTitleSection"
              )}
            >
              {viewMoreService.name}
            </div>
            {energyAllowanceProductIds.indexOf(viewMoreService.id) !== -1 && (
              <FAQSection
                color="#FAFAFA"
                section={faqContent}
                openSection={openSection}
                setOpenSection={section => setOpenSection(section)}
                openQuestion={openQuestion}
                setOpenQuestion={question => setOpenQuestion(question)}
              />
            )}
            {viewMoreService.subName &&
              energyAllowanceProductIds.indexOf(viewMoreService.id) === -1 && (
                <div
                  className={clsx(
                    classes.viewMoreServiceSubName,
                    "quotingTool-serviceDescription"
                  )}
                  dangerouslySetInnerHTML={{
                    __html: viewMoreService.subName,
                  }}
                ></div>
              )}
            {viewMoreService && renderProductFeatures()}
          </div>
        )}
        <div className={classes.closeIconWrapper}>
          <div
            className={classes.closeIconContent}
            onClick={() => setViewMoreService(null)}
          >
            <CloseIcon className={classes.closeIcon} />
          </div>
        </div>
      </Dialog>

      {showProductWarning && (
        <DialogModal open={showProductWarning} quotingTool>
          <div style={{ padding: 20 }}>
            <div style={{ marginBottom: 20, fontWeight: "bold", fontSize: 17 }}>
              Heads Up 👋
            </div>
            <div
              style={{ marginBottom: 20 }}
              dangerouslySetInnerHTML={{
                __html: showProductWarning,
              }}
            />
            <Button onClick={() => setShowProductWarning(null)}>Got it</Button>
          </div>
        </DialogModal>
      )}
      {showCheaperPriceInfo && (
        <Dialog
          open={showCheaperPriceInfo}
          onClose={() => setShowCheaperPriceInfo(false)}
          quotingTool={true}
          className={classes.viewMoreService}
          BackdropProps={{
            style: {
              backgroundColor: "#22544eeb",
            },
          }}
        >
          <div style={{ padding: 20 }}>
            <div>
              <div
                style={{ marginBottom: 20, fontWeight: "bold", fontSize: 17 }}
              >
                Cheaper prices will always be cheaper for a reason.
              </div>

              <div style={{ marginBottom: 10 }}>
                We could offer you a package that costs £5 per person per week,
                but we don’t for a number of reasons:
              </div>
              <div style={{ marginLeft: 10 }}>
                1. You will use much more than this.
              </div>
              <div style={{ marginLeft: 10, marginBottom: 10 }}>
                2. We don’t believe it is fair to mis-sell a package to you.
              </div>
              <div style={{ marginBottom: 10 }}>
                What do we mean by Mis-sell? If you are being offered energy for
                £5 - 10 per week, then the company that is offering this, knows
                full well that in the current market your energy will cost more
                than that. They are luring you in to purchase, before upping
                your price when you are contracted to them.
              </div>
              <div style={{ marginBottom: 10 }}>
                Put it this way, the cost of energy has gone up by over 200%
                since last year due to the energy crisis and energy did not cost
                just £2-3 per week last year.
              </div>
              <div style={{ marginBottom: 10 }}>
                Speak to one of our sales team to go through this in more
                detail. Check our allowances in the energy section of the
                quoting tool and compare with the other provider.
              </div>
            </div>
          </div>

          <div className={classes.closeIconWrapper}>
            <div
              className={classes.closeIconContent}
              onClick={() => setShowCheaperPriceInfo(false)}
            >
              <CloseIcon className={classes.closeIcon} />
            </div>
          </div>
        </Dialog>
      )}
    </div>
  )
}
