import React, { useContext, useCallback } from "react"
import { DateTime } from "luxon"

import {
  getMyTenancy as apiGetMyTenancy,
  updateUserTenancy as apiUpdateUserTenancy,
  updateAdditionalServices as apiUpdateAdditionalServices,
  getTenancyTenants as apiGetTenancyTenants,
  getTenancyContractServices as apiGetTenancyContractServices,
  sendSignUpReminder as apiSendSignUpReminder,
  getAgencies as apiGetAgencies,
} from "../../services/tenantsApiV2"

import {
  GlobalDispatchContext,
  GlobalStateContext,
} from "../../context/GlobalContextProvider"

import PageWrapper from "../../components/PageWrapper"
import AppTitle from "../../components/appTitle/AppTitle"
import Form from "../../components/Form"
import OptimizedTable from "../../components/optimizedTable/OptimizedTable"
import VerifyAccountToContinue from "../../components/verifyAccountToContinue/VerifyAccountToContinue"
import Frame from "../../components/frame/Frame"
import Frames from "../../components/frames/Frames"
import { PSROptions } from "../../constants"
import validator from "validator"
import {
  validateEmail,
} from "../../services/errorValidation"

import * as tenancyInformationStyles from "./tenancyInformation.module.css"


export default ({ location, tenancyId }) => {
  const dispatch = useContext(GlobalDispatchContext)
  const context = useContext(GlobalStateContext)

  const [gettingTenancies, setGettingTenancies] = React.useState(true)
  const [tenancy, storeTenancy] = React.useState(null)
  const [gettingTenancyTenants, setGettingTenancyTenants] = React.useState(
    false
  )
  const [tenants, storeTenants] = React.useState([])
  const [contractServices, storeContractServices] = React.useState([])
  const [
    updatingTenancyInformation,
    setUpdatingTenancyInformation,
  ] = React.useState(false)
  const [
    updateTenancyInformationError,
    setUpdateTenancyInformationError,
  ] = React.useState("")
  const [
    updateTenancyInformationSuccess,
    setUpdateTenancyInformationSuccess,
  ] = React.useState("")
  const [
    updatingAdditionalServices,
    setUpdatingAdditionalServices,
  ] = React.useState(false)
  const [
    updateAdditionalServicesError,
    setUpdateAdditionalServicesError,
  ] = React.useState("")
  const [
    updateAdditionalServicesSuccess,
    setUpdateAdditionalServicesSuccess,
  ] = React.useState("")
  const [getMyTenancyError, setGetMyTenancyError] = React.useState(null)
  const [sendingReminderTo, setSendingReminderTo] = React.useState(null)
  const [sentRemindersTo, setSentRemindersTo] = React.useState([])

  const [agencies, setAgencies] = React.useState([])

  const getTenancyTenants = useCallback(async () => {
    try {
      setGettingTenancyTenants(true)
      const data = await Promise.all([
        apiGetTenancyTenants(tenancyId),
        apiGetTenancyContractServices(tenancyId),
      ])

      if (data[0] && data[0].value) {
        storeTenants(data[0].value)
      }

      if (data[1] && data[1].value) {
        storeContractServices(data[1].value)
      }
    } catch (e) {
      console.log(e)
    }
    setGettingTenancyTenants(false)
  }, [tenancyId])

  const getMyTenancy = useCallback(async () => {
    try {
      setGettingTenancies(true)
      const data = await apiGetMyTenancy(tenancyId)
      if (data.value) {
        storeTenancy(data.value)
        getTenancyTenants()
      }
    } catch (e) {
      console.log(e)
      setGetMyTenancyError(e.message)
    }
    setGettingTenancies(false)
  }, [getTenancyTenants, tenancyId])

  const sendSignUpReminder = async tenantId => {
    try {
      setSendingReminderTo(tenantId)
      await apiSendSignUpReminder(tenantId)
      setSendingReminderTo(null)
      setSentRemindersTo([...sentRemindersTo, tenantId])
    } catch (e) {
      console.log(e)
    }
  }

  const getAgencies = async () => {
    try {
      const data = await apiGetAgencies()
      if (data.value) {
        setAgencies(data.value)
      }
    } catch (e) {
      console.log(e)
    }
  }

  React.useEffect(() => {
    getMyTenancy()
  }, [context.loggedIn, getMyTenancy])

  React.useEffect(() => {
    getAgencies()
  }, [])

  function validateName(name) {
    return (
      name.trim() !== "" &&
      validator.isAlpha(name, "en-GB", {
        ignore: " -",
      })
    )
  }

  const updateTenancyInformation = async payload => {

    if (payload.broadbandStartDate !== tenancy.broadbandStartDate && new Date(payload.broadbandStartDate) < new Date(tenancy.broadbandStartDate)) {
      return setUpdateTenancyInformationError("New broadband start date must be after the initial start date")
    }

    if (payload.broadbandStartDate !== tenancy.broadbandStartDate && new Date(payload.broadbandStartDate) < new Date()) {
      return setUpdateTenancyInformationError("Broadband start date cannot be updated if it has already begun")
    }
    if (payload.isVulnerablePerson && payload.psrCategory.length < 1) {
      return setUpdateTenancyInformationError("Please select the PSR reason")
    }
    if (payload.psrAuthorisedContactName ||  payload.psrAuthorisedContactEmail || payload.psrAuthorisedContactDateOfBirth){
      if (!payload.psrAuthorisedContactName ||  !payload.psrAuthorisedContactEmail || !payload.psrAuthorisedContactDateOfBirth){
        return setUpdateTenancyInformationError("If an authorised contact is specified, name, email and date of birth are all required.")
      }
    }
    if (!validateName(payload.psrAuthorisedContactName)){
      return setUpdateTenancyInformationError("Authorised contact name can only contain letters and '-' characters.")
    }

    if (payload.psrAuthorisedContactName.length > 200){
      return setUpdateTenancyInformationError("The authorised contact name can only be up to 200 characters in length")
    }
    if (payload.psrAuthorisedContactEmail.length > 320){
      return setUpdateTenancyInformationError("The authorised contact email can only be up to 320 characters in length")
    }
    if (!validateEmail(payload.psrAuthorisedContactEmail)){
      return setUpdateTenancyInformationError("The authorised contact email is invalid")
    }

    if (payload.psrAuthorisedContactDateOfBirth){
      const authContactDate = new Date(payload.psrAuthorisedContactDateOfBirth);

      if (authContactDate > DateTime.local().plus({ years: -18 })){
        return setUpdateTenancyInformationError("The authorised contact must be at least 18 years old")
      }
    }

    payload["isAgencyUpdated"] = payload.agencyName === tenancy.agencyName ? false : true

    payload["isResiTypeUpdated"] = payload.residentialType === tenancy.residentialType ? false : true

    payload["isBroadbandStartDateUpdated"] = payload.broadbandStartDate === tenancy.broadbandStartDate ? false : true

    payload["isPsrUpdated"] = payload.isVulnerablePerson === tenancy.isVulnerablePerson 
    && (payload.psrCategory === tenancy.psrCategory || payload.psrCategory === null) 
    && (payload.psrAuthorisedContactName === tenancy.psrAuthorisedContactName || payload.psrAuthorisedContactName === null) 
    && (payload.psrAuthorisedContactEmail === tenancy.psrAuthorisedContactEmail || payload.psrAuthorisedContactEmail === null)
    && (payload.psrAuthorisedContactDateOfBirth === tenancy.psrAuthorisedContactDateOfBirth || payload.psrAuthorisedContactDateOfBirth === null) ? false : true

    const isTenancyAgreementUploaded = payload.tenancyAgreement ? true : false

    const isLoaUploaded = payload.loa ? true : false

    if (!payload.isAgencyUpdated && !payload.isResiTypeUpdated && !payload.isBroadbandStartDateUpdated && !payload.isPsrUpdated && !isTenancyAgreementUploaded && !isLoaUploaded) {
      return setUpdateTenancyInformationError("No data has been updated")
    }

    setUpdateTenancyInformationError("")

    try {
      setUpdatingTenancyInformation(true)

      if (payload.psrAuthorisedContactDateOfBirth){
        const authContactDate = new Date(payload.psrAuthorisedContactDateOfBirth);

        payload.psrAuthorisedContactDateOfBirth = authContactDate.toISOString();
      }

      const data = await apiUpdateUserTenancy(tenancyId, {
        ...context.tenantUser,
        ...payload
      })

      if (data.success) {
        setUpdateTenancyInformationSuccess("Tenancy updated")
      } else if (data.errors) {
        setUpdateTenancyInformationError(data.errors[0].reason)
      } else {
        setUpdateTenancyInformationError(
          "An error occurred trying to update profile"
        )
      }
      setUpdatingTenancyInformation(false)
      getMyTenancy(tenancyId)
    } catch (e) {
      setUpdatingTenancyInformation(false)
      setUpdateTenancyInformationError(e.message)
    }
  }

  const updateAdditionalServices = async payload => {
    if (payload.isTvLicenceConfigured === tenancy.isTvLicenceConfigured && payload.isCouncilTaxConfigured === tenancy.isCouncilTaxConfigured) {
      return setUpdateAdditionalServicesError("No data has been updated")
    }

    setUpdateAdditionalServicesError("")

    try {
      setUpdatingAdditionalServices(true)

      const data = await apiUpdateAdditionalServices(tenancyId, {
        ...context.tenantUser,
        ...payload,
      })

      if (data.success) {
        setUpdateAdditionalServicesSuccess("Updated")
      } else if (data.errors) {
        setUpdateAdditionalServicesError(data.errors[0].reason)
      } else {
        setUpdateAdditionalServicesError(
          "An error occurred trying to update details"
        )
      }
      setUpdatingAdditionalServices(false)
      getMyTenancy(tenancyId)
    } catch (e) {
      setUpdatingAdditionalServices(false)
      setUpdateAdditionalServicesError(e.message)
    }
  }

  const renderForm = () => {
    if (tenancy) {

      // These fields are just shown to the user, they can't be updated
      const unmodifiableTenancyFormOptions = {
        fields: [
          {
            name: "address",
            placeholder: "Address",
            type: "text",
            disabled: true,
          },
          [
            {
              name: "startDate",
              placeholder: "Start date",
              type: "date",
              // disabled: !tenancy.isLeadTenant,
              disabled: true,
              clearable: true,
            },
            {
              name: "endDate",
              placeholder: "End date",
              type: "date",
              // disabled: !tenancy.isLeadTenant,
              disabled: true,
              clearable: true,
            },
          ],
          [
            {
              name: "moveInDate",
              placeholder: "Move in date",
              type: "date",
              // disabled: !tenancy.isLeadTenant,
              disabled: true,
              clearable: true,
            },
            {
              name: "moveOutDate",
              placeholder: "Move out date",
              type: "date",
              // disabled: !tenancy.isLeadTenant,
              disabled: true,
              clearable: true,
            },
          ],
          [
            {
              name: "package",
              placeholder: "Package",
              type: "text",
              disabled: true,
            },
          ],
        ],
        // submitText: tenancy.isLeadTenant && "Save",
      }

      const updateTenancyFormOptions = {
        fields: [
          {
            type: "break",
          },
          {
            name: "broadbandStartDate",
            placeholder: "Broadband start date",
            type: "date",
            clearable: true,
            showIfTrue: !tenancy.hasBroadband,
            disabled: new Date(tenancy.broadbandStartDate) < new Date()
          },
          {
            name: "agencyName",
            id: "agencyId",
            label: tenancy.agencyName ? "Agency/Landlord" : "Agency/Landlord*",
            placeholder: tenancy.agencyName ? tenancy.agencyName : "Enter your agency or landlord",
            type: "searchableDropdown",
            options: agencies.map(agency => ({ label: agency.name, value: agency.zohoEntityId })),
          },
          {
            name: "residentialType",
            label: "Household Type",
            placeholder: "Type",
            type: "dropdown",
            options: [{ label: "Renter", value: "Renter" }, { label: "Homeowner", value: "Homeowner" }],
            showIfTrue: !tenancy.name.includes("Residential"),
            tenancyLabel: true
          },
          {
            name: "tenancyAgreement",
            label: tenancy.tenancyAgreementUploaded ? "Tenancy Agreement" : "Tenancy Agreement*",
            placeholder: tenancy.tenancyAgreementUploaded ? "Replace Tenancy Agreement" : "Upload Tenancy Agreement",
            type: "selectPdf",
            currentFile: tenancy.tenancyAgreement
          },
          {
            name: "/loaDownload.pdf",
            label: tenancy.loaUploaded ? "Letter of Authority" : "Letter of Authority*",
            type: "downloadFile"
          },
          {
            name: "loa",
            label: tenancy.loaUploaded ? "Letter of Authority" : "Letter of Authority*",
            placeholder: tenancy.loaUploaded ? "Replace Letter of Authority" : "Upload Letter of Authority",
            type: "selectPdf",
            currentFile: tenancy.loa
          },
          {
            type: "break"
          },
          {
            name: "isVulnerablePerson",
            label: "Are you registered with the Priority Services Register? Please tick here and select the reason from the list below?",
            type: "checkbox",
          },
          {
            name: "psrCategory",
            type: 'dropdown',
            placeholder: 'PSR Category',
            options: PSROptions.map(psrOption => ({ label: psrOption, value: psrOption })),
            showIfTrue: ["isVulnerablePerson"],
            multiSelect: true,
            label: 'PSR Category'
          },
          {
            name: "psrAuthorisedContactName",
            placeholder: "PSR Authorised Contact Name",
            type: "text",
            showIfTrue: ["isVulnerablePerson"],
          },
          {
            name: "psrAuthorisedContactEmail",
            placeholder: "PSR Authorised Contact Email",
            type: "email",
            showIfTrue: ["isVulnerablePerson"],
          },
          {
            name: "psrAuthorisedContactDateOfBirth",
            placeholder: "PSR Authorised Contact Date of Birth",
            type: "date",
            clearable: true,
            showIfTrue: ["isVulnerablePerson"],
          },
        ],
        submitText: "Save",
      }

      const payload = tenancy
      payload["package"] = tenancy?.unlimited ? "Unlimited" : "PAYG"
      return (
        <Frame>
          <Form
            options={unmodifiableTenancyFormOptions}
            initialPayload={payload}
            showCallUs={false}
            reducedPadding={true}
            // submitting={updatingTenancyInformation}
            apiErrorMessage={updateTenancyInformationError}
            apiSuccessMessage={updateTenancyInformationSuccess}
          // onSubmit={payload => updateTenancyInformation(payload)}
          // submitHelperText={
          //   tenancy.isLeadTenant &&
          //   "Submit your changes here, and they will update once we have processed them."
          // }
          />
          <Form
            options={updateTenancyFormOptions}
            initialPayload={payload}
            showCallUs={false}
            reducedPadding={true}
            submitting={updatingTenancyInformation}
            apiErrorMessage={updateTenancyInformationError}
            apiSuccessMessage={updateTenancyInformationSuccess}
            onSubmit={payload => updateTenancyInformation(payload)}
          />
        </Frame>
      )
    }
    return null
  }

  const renderAdditionalServicesForm = () => {
    if (tenancy) {

      const updateAdditionalServicesOptions = {
        fields: [
          {
            name: "isTvLicenceConfigured",
            type: "checkbox",
            linkText: "TV licence",
            link: "https://www.tvlicensing.co.uk/cs/pay-for-your-tv-licence/index.app"
          },
          {
            name: "isCouncilTaxConfigured",
            type: "checkbox",
            linkText: "Council tax",
            link: tenancy.councilTaxLink
          }
        ],
        submitText: "Save",
      }

      const payload = tenancy
      return (
        <Frame
          title={"Additional Services"}
          description={"Have you thought about your other household services? Use the links below to set them up."}
        >
          <div style={{ fontStyle: "italic" }}>
            Bunch does not provide or manage these services. These external links are here to simplify your setup.
            Use the checkboxes as a reminder, to confirm once you have set up the services.
          </div>
          <Form
            options={updateAdditionalServicesOptions}
            initialPayload={payload}
            showCallUs={false}
            reducedPadding={true}
            submitting={updatingAdditionalServices}
            apiErrorMessage={updateAdditionalServicesError}
            apiSuccessMessage={updateAdditionalServicesSuccess}
            onSubmit={payload => updateAdditionalServices(payload)}
          />
        </Frame>
      )
    }
    return null;
  }

  const renderSendSignUpReminderAction = tenant => {
    if (sendingReminderTo == tenant.zohoEntityId) {
      return <div>Sending...</div>
    }

    if (sentRemindersTo.indexOf(tenant.zohoEntityId) !== -1) {
      return <div>Reminder sent</div>
    }

    return (
      <div
        style={{ cursor: "pointer" }}
        onClick={() => sendSignUpReminder(tenant.zohoEntityId)}
      >
        Send reminder
      </div>
    )
  }

  return (
    <PageWrapper
      location={location}
      showSidebar={true}
      showPortalHeader={true}
      hideHeader={true}
      showLoadingIndicator={gettingTenancies || gettingTenancyTenants}
      showLoadingLogo={true}
      showLoadingText="Getting your live data"
      tenancy={tenancy}
    >
      <div className={tenancyInformationStyles.tenancyDetails}>
        <AppTitle title="Your Contract" />
        {getMyTenancyError &&
          getMyTenancyError === "Please verify your account to continue" && (
            <VerifyAccountToContinue />
          )}
        {tenancy && (
          <>
            {renderForm()}
            <div style={{ marginTop: 20 }}>
              <Frames>
                <Frame title="Your Household">
                  <OptimizedTable
                    rows={tenants.map(tenant => [
                      [
                        {
                          type: "title",
                          value: `${tenant.firstName} ${tenant.lastName}`,
                        },
                        {
                          type: "status",
                          value: tenant.hasSignedUp
                            ? "Signed up"
                            : "Not signed up",
                        },
                      ],
                      [
                        { type: "text", value: tenant.email },
                        {
                          type: "text",
                          value: tenant.hasSignedUp ? (
                            <div />
                          ) : (
                            renderSendSignUpReminderAction(tenant)
                          ),
                        },
                      ],
                    ])}
                  />
                </Frame>

                {contractServices && (
                  <Frame title="Your Services">
                    <OptimizedTable
                      rows={contractServices.map(contractService => [
                        [
                          { type: "title", value: contractService.name },
                          { type: "status", value: `Status: ${contractService.status}` },
                        ],
                        [
                          { type: "text", value: contractService.supplier },
                          { type: "text", value: contractService.subName },
                        ],
                      ])}
                    />
                  </Frame>
                )}
              </Frames>
            </div>

            <div style={{ marginTop: 20 }}>
              <Frames>
                {/*renderAdditionalServicesForm()*/}
              </Frames>
            </div>
          </>
        )}
      </div>
    </PageWrapper>
  )
}
